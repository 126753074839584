import Typed from 'typed.js';

var options = {
    stringsElement: '#typed-strings',
    typeSpeed: 75,
    startDelay: 300,
    backSpeed: 75,
    shuffle: true,
    backDelay: 1500,
    loop: true,
    cursorChar: '|',
};

new Typed('.typed', options);